import styled from 'styled-components';
import dotList from '../../../images/dot-list.svg';
import {GlobalColors, GlobalFonts} from '../../../styles/GlobalStyles';

export const ContDesarrollosDetalles = styled.div`
  margin-bottom: 50px;
`
export const ContUlDetalles = styled.div`
  width: 90%;
  margin: 0 auto;
`
export const UlDetalles = styled.ul`
  margin-left: 15px;
  margin-bottom: 30px;
  list-style-image: url(${dotList});
  
  & > li{
    margin-bottom: 15px;
    font-family: ${GlobalFonts.fontRegular};
    font-size: 16px;
    color: ${GlobalColors.colorMenuPrimary};
  }
`
export const ContDetalles = styled.div`
  width: 50%;
  margin: 0 auto;
  
  @media(min-width: 768px) and (max-width: 1023px){
    width: 40%;
  }
`
export const TitleManzanas = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  
  margin-bottom: 25px;
  
  font-family: ${GlobalFonts.fontBold};
  font-size: 32px;
  color: ${GlobalColors.colorMenuPrimary};
`
export const ContItemDetalle = styled.div`
  //width: 90%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  
  margin: 0 auto 15px;
  padding: 0 0 15px;
  
  border-bottom: 1px solid ${props => props.color || GlobalColors.colorBorderDark};    
`
export const ItemDetalle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`
export const ContVerticalLine = styled.div`
  width: 1px;
  height: 50px;
  background-color: ${props => props.color || GlobalColors.colorBorderDark};    
`
export const TitleManzanaDetalle = styled.div`
  margin: 0 auto;
  font-family: ${GlobalFonts.fontRegular};
  font-size: 32px;
  color: ${GlobalColors.colorMenuSecondary};
  
  @media(min-width: 300px) and (max-width: 767px){
    margin: 0 auto 30px;
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin: 0 auto 30px;
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const TitleDetalle = styled.div`
  font-family: ${GlobalFonts.fontRegular};
  font-size: 16px;
  color: ${GlobalColors.colorMenuSecondary};
`
export const TextoDetalle = styled.div`
  font-family: ${GlobalFonts.fontBold};
  font-size: 28px;
  color: ${props => props.color || GlobalColors.colorMenuPrimary};
  
  & > sup{
    font-size: 16px;
  }
  & > span{
    font-size: 16px;
  }
`
export const TitleFacilidades = styled.div`
  margin-bottom: 15px;
  font-family: ${GlobalFonts.fontBold};
  font-size: 20px;
  color: ${GlobalColors.colorMenuPrimary};
`
