import styled from 'styled-components';
import {GlobalBackgroundColors} from '../../../styles/GlobalStyles';

export const ContSectionPlano = styled.div`
  height: auto;
  background-color: ${GlobalBackgroundColors.bgGreyDark};
`
export const ContImgPlano = styled.div`
  width: 100%;
  height: 100%;
  
  margin-bottom: 50px;
  
  display: flow-root;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 30px;
    padding-top: 0px;  
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 30px;
    padding-top: 0px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding-top: 75px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding-top: 120px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    padding-top: 125px;
  }
  @media(min-width: 1920px){
    padding-top: 125px;
  }
`
export const ImgPlano = styled.img`
  width: 100%;
  height: auto;
`
