import React from 'react';
import {Container, Columns, Column} from 'bloomer';
import {ContTitle} from '../../../styles/GlobalStyles';
import {
  ContDesarrollosDetalles,
  ContDetalles,
  ContItemDetalle, ContUlDetalles,
  ContVerticalLine,
  ItemDetalle,
  TextoDetalle,
  TitleDetalle, TitleFacilidades,
  TitleManzanas,
  UlDetalles,
} from './stylesDesarrollosDetallesDelValle';

export const DesarrollosDetallesDelValleComp = () => {

  return (
    <ContDesarrollosDetalles>
      <Container>
        <ContTitle>Detalles</ContTitle>

        <Columns className="columns-responsive full-height">
          <Column isSize={{mobile: 0, tablet: 0, desktop: 3, widescreen: 3}} className={'no-display-mobile'}>
            &nbsp;
          </Column>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
            <ContUlDetalles>
              <UlDetalles>
                <li>
                  Se ubica a 15 min. de Puerto Escondido en Rio Valdeflores, Santa María Colotepec, Pochutla, Oaxaca.
                </li>
                <li>Es terreno comunal, lo que se tramita es un acta de posesión.</li>
                <li>Cuenta con 511 lotes.</li>
                <li>No cuenta con servicios.</li>
                <li>Las playas cercanas son "el puertecito" a 8 minutos y "agua blanca" a 10 minutos.</li>
                <li>Se ubica a 8 minutos de la laguna de Manialtepec.</li>
              </UlDetalles>
            </ContUlDetalles>
          </Column>
          <Column isSize={{mobile: 0, tablet: 0, desktop: 3, widescreen: 3}} className={'no-display-mobile'}>
            &nbsp;
          </Column>
        </Columns>

        <Columns className="columns-responsive full-height">
          <Column isSize={{mobile: 0, tablet: 0, desktop: 3, widescreen: 3}} className={'no-display-mobile'}>
            &nbsp;
          </Column>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
            <ContDetalles>

              <TitleManzanas>
                <div>Lotes</div>
              </TitleManzanas>

              <ContItemDetalle>
                <ItemDetalle>
                  <TitleDetalle>Medidas</TitleDetalle>
                  <TextoDetalle>10x20</TextoDetalle>
                </ItemDetalle>

                <ItemDetalle>
                  <ContVerticalLine/>
                </ItemDetalle>

                <ItemDetalle>
                  <TitleDetalle>Total</TitleDetalle>
                  <TextoDetalle>200 m<sup>2</sup>.</TextoDetalle>
                </ItemDetalle>
              </ContItemDetalle>

              <ContItemDetalle>
                <ItemDetalle>
                  <TitleDetalle>Contado</TitleDetalle>
                  <TextoDetalle>$45,000 <span>MXN</span></TextoDetalle>
                </ItemDetalle>
              </ContItemDetalle>

              <TitleFacilidades>Facilidades</TitleFacilidades>

              <ContItemDetalle>
                <ItemDetalle>
                  <TitleDetalle>26 meses</TitleDetalle>
                  <TextoDetalle>$2,500 <span>MXN</span></TextoDetalle>
                </ItemDetalle>
              </ContItemDetalle>

            </ContDetalles>
          </Column>

          <Column isSize={{mobile: 0, tablet: 0, desktop: 3, widescreen: 3}} className={'no-display-mobile'}>
            &nbsp;
          </Column>
        </Columns>

      </Container>

    </ContDesarrollosDetalles>
  );
};
