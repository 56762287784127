import styled, { createGlobalStyle }  from 'styled-components';
import {GlobalColors, GlobalFonts} from "../../../styles/GlobalStyles";

export const GlobalStylesItemTabsPlano = createGlobalStyle`
  .display-no{
    display: none !important;
  }
  
  #manzana-1{
    @media(min-width: 300px) and (max-width: 767px){
      top: 140px;
      left: 200px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 300px;
      left: 425px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 50px;
      left: 260px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 60px;
      left: 315px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 300px;
      left: 365px;
    }
    @media(min-width: 1920px){
      top: 300px;
      left: 365px;
    }
  }
  #manzana-2{
    @media(min-width: 300px) and (max-width: 767px){
      top: 100px;
      left: 117px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 230px;
      left: 260px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 205px;
      left: 160px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 200px;
      left: 200px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 230px;
      left: 235px;
    }
    @media(min-width: 1920px){
      top: 230px;
      left: 235px;
    }
  }
  #manzana-3{
    @media(min-width: 300px) and (max-width: 767px){
      top: 10px;
      left: 280px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 35px;
      left: 599px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 85px;
      left: 370px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 50px;
      left: 450px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 60px;
      left: 525px;
    }
    @media(min-width: 1920px){
      top: 60px;
      left: 530px;
    }
  }
  #manzana-4{
    @media(min-width: 300px) and (max-width: 767px){
      top: 100px;
      left: 115px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      top: 215px;
      left: 255px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      top: 195px;
      left: 154px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      top: 190px;
      left: 185px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      top: 215px;
      left: 215px;
    }
    @media(min-width: 1920px){
      top: 215px;
      left: 215px;
    }
  }
`
export const ContDetalles = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 85%;
    /*padding: 30px 0 30px 0;*/
    padding: 0;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 40%;
    padding: 20px 0 30px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding: 40px 0 40px 0;    
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding: 50px 0 40px 0;
  }
  @media(min-width: 1408px){
    padding: 50px 0 40px 0;
  }
`
export const ContPlano = styled.div`
  width: 100%;
  height: 100%;
  
  margin-bottom: 50px;
  
  display: flow-root;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 30px;
    padding-top: 0;  
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 30px;
    padding-top: 0;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    padding-top: 0;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    padding-top: 0;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    padding-top: 0;
  }
  @media(min-width: 1920px){
    padding-top: 0;
  }
`
export const ContImgPlano = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ImgPlano = styled.img`
  width: 100%;
  height: auto;
`
export const ContBtnsLightbox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`
export const BtnLightbox = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  padding-bottom: 5px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  
  border: 2px solid ${GlobalColors.colorPrimary};
  border-radius: 50%;
  background-color: ${GlobalColors.colorTertiary};
  
  font: 28px ${GlobalFonts.fontMedium};
  color: ${GlobalColors.colorPrimary};
  
  cursor: pointer;
  transition: .3s;
  
  &:hover{
    width: 43px;
    height: 43px;
  }
`
export const ContDetalleInfo = styled.div`
  margin: 0 auto 30px;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 80%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 80%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 50%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 250px;
    margin: 0 auto;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 250px;
    margin: 0 auto;
  }
  @media(min-width: 1920px){
    width: 250px;
    margin: 0 auto;
  }
`
