import * as React from "react"
import LayoutPages from '../components/Layout/layoutPages';
import Seo from "../components/seo"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import DelValleComp from '../components/DelValle';

const DelVallePage = (props) => {
  const { title, siteUrl } = useSiteMetadata();

  return (
    <LayoutPages pathname={props.location.pathname}>
      <Seo
        title={title}
        description="Seguridad y confianza en tu patrimonio"
        keywords='patrimonio, terrenos, bienes raices, inmobiliaria'
        lang={`es`}
        imageF='inmoax-del-valle-f.jpg'
        imageT='inmoax-del-valle-t.jpg'
        url={`${siteUrl}/`}
      />

      <DelValleComp {...props} />

    </LayoutPages>
  )
}

export default DelVallePage
