import React from 'react';
import {Container, Columns, Column} from 'bloomer';
import {PopupboxContainer, PopupboxManager} from 'react-popupbox';
import 'react-popupbox/dist/react-popupbox.css';
import {GlobalStylePopupbox} from '../../../styles/GlobalStylesPopupbox';
import {GlobalColors} from '../../../styles/GlobalStyles';
import {
  ContDetalles,
  GlobalStylesItemTabsPlano,
  ContImgPlano,
  ImgPlano,
  ContPlano,
  BtnLightbox, ContBtnsLightbox, ContDetalleInfo,
} from './stylesItemTabsPlano';
import {
  ContItemDetalle, ContVerticalLine,
  ItemDetalle, TextoDetalle, TitleDetalle, TitleManzanaDetalle,
} from '../../DesarrollosDetalles/PuntaEscondida/stylesDesarrollosDetallesPuntaEscondida';
import planoDesarrollo from '../../../images/desarrollos/del-valle/plano-del-valle.jpg';
import planoDetalle from '../../../images/desarrollos/del-valle/plano-del-valle.jpg';

const ItemTabsPlano = ({data}) => {

  const openPopupbox = (title, file, css) => {
    const content = <img className={css} src={file} alt={title}/>;
    PopupboxManager.open({
      content,
      config: {
        titleBar: {
          enable: true,
          text: title,
        },
        fadeIn: true,
        fadeInSpeed: 500,
      },
    });
  };

  return (
    <Container isFluid={true} className={' padding-bottom-plano'}>
      <GlobalStylePopupbox/>
      <GlobalStylesItemTabsPlano/>

      <Columns className={'columns-margin-side-no'}>

        <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>

          <ContDetalleInfo>

            <TitleManzanaDetalle>
              Lotes
            </TitleManzanaDetalle>

            <ContDetalles>
              <ContItemDetalle>
                <ItemDetalle>
                  <TitleDetalle>Medidas</TitleDetalle>
                  <TextoDetalle color={GlobalColors.colorLight}>10x20</TextoDetalle>
                </ItemDetalle>

                <ItemDetalle>
                  <ContVerticalLine/>
                </ItemDetalle>

                <ItemDetalle>
                  <TitleDetalle>Total</TitleDetalle>
                  <TextoDetalle color={GlobalColors.colorLight}>200 m<sup>2</sup>.</TextoDetalle>
                </ItemDetalle>
              </ContItemDetalle>

              <ContItemDetalle>
                <ItemDetalle>
                  <TitleDetalle>Contado</TitleDetalle>
                  <TextoDetalle color={GlobalColors.colorLight}>$45,000 <span>MXN</span></TextoDetalle>
                </ItemDetalle>
              </ContItemDetalle>
            </ContDetalles>
          </ContDetalleInfo>


        </Column>

        <Column className={'global-columns-paddign-no'} isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
          <ContPlano>
            <ContImgPlano id={'contPlano1y3'}>
              <ImgPlano src={planoDesarrollo} alt={''}/>

              <ContBtnsLightbox>
                <BtnLightbox
                  id={'del-valle-lotes'}
                  onClick={() => openPopupbox('Lotes', planoDetalle, 'popupbox-img-width')}>+</BtnLightbox>
              </ContBtnsLightbox>

            </ContImgPlano>
          </ContPlano>
        </Column>

      </Columns>

      <PopupboxContainer/>

    </Container>
  );
};

export default ItemTabsPlano;
