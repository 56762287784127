import React from 'react';
import {GlobalStyle} from '../Home/styles';
import {DelValleBackComp} from '../DesarrollosBack/DelValle/DelValleBack';
import ImgBack from '../../images/desarrollos/del-valle/del-valle-g.jpg';
import {DesarrollosDetallesDelValleComp} from '../DesarrollosDetalles/DelValle/DesarrollosDetallesDelValle';
import {PlanoDelValleComp} from '../Planos/DelValle/Plano';
import {UbicacionComp} from '../Ubicacion/';

const title = 'Del Valle';
const lugar = 'Se ubica a 15 min. de Puerto Escondido.';

const DelValleComp = () => {

  return (
    <div className="cont-page page-home">
      <GlobalStyle/>
      <DelValleBackComp title={title} lugar={lugar} img={ImgBack}/>
      <DesarrollosDetallesDelValleComp/>
      <PlanoDelValleComp/>
      <UbicacionComp desarrollo={'delvalle'}/>
    </div>
  )
}

export default DelValleComp
